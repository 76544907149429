import axios from "axios";

const API_URL = process.env.VUE_APP_API_URL + '/api/product'

class ProductService {
  getProductById(id) {
    return axios.get(API_URL + '/getProductById/' + id)
  }


  createProduct(data) {
    return axios.post(API_URL + '/createProduct', data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  }
  deleteProduct(id) {
    return axios.post(API_URL + '/deleteProduct', {id})
  }
  updateProduct(data) {
    return axios.post(API_URL + '/updateProduct', data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  }
}

export default new ProductService()
