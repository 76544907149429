import axios from "axios";

const API_URL = process.env.VUE_APP_API_URL + '/api/category'

class CategoryService {
  getCategoryById(id) {
    return axios.get(API_URL + '/getCategoryById/' + id)
  }


  getCategories() {
    return axios.get(API_URL + '/getCategories')
  }
  createCategory(data) {
    return axios.post(API_URL + '/createCategory', data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  }
  deleteCategory(id) {
    return axios.post(API_URL + '/deleteCategory', {id})
  }
  updateCategory(data) {
    return axios.post(API_URL + '/updateCategory', data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  }

  getCategoryProducts(id) {
    return axios.get(API_URL + `/getCategoryProducts/${id}`)
  }
}

export default new CategoryService()
