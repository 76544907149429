<template>
  <v-container class="admin mt-lg-16">
    <v-row>
      <v-col cols="12" class="position-relative">
        <div class="d-flex flex-column flex-md-row justify-space-between">
          <h2 class="admin-title">Административная панель</h2>
          <div class="admin-links align-self-center">
            <v-btn class="button admin-links__link" :class="{'admin-links__link-active': activeLink === 'mails'}" @click="$router.push({name: 'mails'})">Регистрации и заказы</v-btn>
            <v-btn class="button ml-sm-6 admin-links__link" :class="{'admin-links__link-active': activeLink === 'categories'}" @click="$router.push({name: 'categories'})">Категории и товары</v-btn>
          </div>
        </div>
      </v-col>
    </v-row>

    <router-view></router-view>
  </v-container>
</template>

<script>
import CategoryService from "@/services/category.service";
import ProductService from "@/services/product.service";
export default {
  props: ['windowWidth'],
  data() {
    return {
      activeCategory: 0,
      categories: [],
      category: {
        name: ''
      },
      product: {
        name: '',
        categoryId: '',
        desc: '',
        image: null
      },
    }
  },
  computed: {
    activeLink() {
      return this.$route.name
    }
  },
  mounted() {
    CategoryService.getCategories().then(response => {
      this.categories = response.data
    })
  },
  methods: {
    createCategory() {
      let valid = this.$refs.createCategoryForm.validate()

      if (valid) {
        CategoryService.createCategory(this.category).then(response => {
          this.categories.push(response.data.category)
          this.$refs.createCategoryForm.reset()
        })
      }
    },
    updateCategory() {
      let valid = this.$refs.updateCategoryForm.validate()

      if (valid) {
        CategoryService.updateCategory(this.updateCategoryDialogData).then(response => {
          this.categories[this.updateCategoryDialogData.index] = response.data.category
          this.closeUpdateCategoryDialog()
        })
      }
    },
    deleteCategory() {
      if (this.confirmDeleteCategory.check) {
        CategoryService.deleteCategory(this.confirmDeleteCategory.id).then(() => {
          this.categories.splice(this.confirmDeleteCategory.index, 1)
          this.closeConfirmDeleteCategory()
        })
      }
    },
    createProduct() {
      let valid = this.$refs.createProductForm.validate()

      if (valid) {
        const formData = new FormData()

        formData.append('image', this.product.image)
        formData.append('categoryId', this.product.categoryId)
        formData.append('name', this.product.name)
        formData.append('desc', this.product.desc)

        ProductService.createProduct(formData).then(response => {
          let index = this.categories.findIndex(el => el.id === this.product.categoryId)
          this.categories[index].products.push(response.data.product)
          this.$refs.createProductForm.reset()
        })
      }
    },
    updateProduct() {
      let valid = this.$refs.updateProductForm.validate()

      if (valid) {
        const formData = new FormData()

        if (this.updateProductDialogData.image) {
          formData.append('image', this.updateProductDialogData.image)
        }
        formData.append('id', this.updateProductDialogData.id)
        formData.append('categoryId', this.updateProductDialogData.categoryId)
        formData.append('categoryIdNew', this.updateProductDialogData.categoryIdNew)
        formData.append('name', this.updateProductDialogData.name)
        formData.append('desc', this.updateProductDialogData.desc)

        ProductService.updateProduct(formData).then(response => {
          if (this.updateProductDialogData.categoryId !== this.updateProductDialogData.categoryIdNew) {
            this.categories[this.updateProductDialogData.categoryIndex].products.splice(this.updateProductDialogData.index, 1)
            this.categories[this.updateProductDialogData.categoryIdNew].products.push(response.data.product)
          } else {
            this.categories[this.updateProductDialogData.categoryIndex].products[this.updateProductDialogData.index] = response.data.product
          }

          this.closeUpdateProductDialog()
        })
      }
    },
    deleteProduct() {
      if (this.confirmDeleteProduct.check) {
        ProductService.deleteProduct(this.confirmDeleteProduct.id).then(() => {
          this.categories[this.confirmDeleteProduct.categoryIndex].products.splice(this.confirmDeleteProduct.index, 1)
          this.closeConfirmDeleteProduct()
        })
      }
    }
  }
}
</script>

<style scoped lang="sass">
.admin
  &-title
    font-size: 36px
  &-links
    display: flex
    &__link
      background-color: rgba(0,0,0,0)
      border: 1px solid #018ABE
      &-active
        color: #ffffff
        background-color: #018ABE !important
@media (max-width: 1263.98px)
  .admin
    &-title
      font-size: 28px
@media (max-width: 959.98px)
  .admin
    &-title
      text-align: center
      margin-bottom: 24px
@media (max-width: 599.98px)
  .admin
    &-title
      font-size: 18px
    &-links
      display: flex
      flex-direction: column
      &__link
        background-color: rgba(0,0,0,0)
        border: 1px solid #018ABE
        margin-bottom: 12px
</style>
